.meal-plan-wrapper {
	width: 100%;
	margin: 0 auto;
	text-align: right;

	.meal-plan-logo {
		margin-bottom: 25px;
		display: inline-block;

		@media screen {
			display: none;
		}
	}

	.meal-plan-heading {
		float: left;
		color: #FFF;
		margin-top: 15px;
	}

	.modal-dialog {
		max-width: 640px;
		-webkit-overflow-scrolling: touch;
		max-height: calc(100vh - 60px);
		overflow: auto;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 0.3rem;
	}

	.modal-content {
		border: none;
		border-radius: 0;
	}

}

.meal-plan {
	text-align: left;
	margin: 0 auto;

	.row-wrapper {
		width: calc(100vw - 30px);
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		@media screen and (max-width: 1023px) {
			> .row {
				min-width: 1300px;
			}
		}
	}

	.head {
		flex-wrap: nowrap;
		text-align: center;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-size: 1.2em;
		font-weight: 200;
	}

	.meal-block {
		border-radius: 5px;
		margin-bottom: 10px;
		font-size: 0.8em;
		transition: box-shadow 250ms, background-color 250ms;
		position: relative;

		&.training {
			height: 25px;

			&.active {
				background-color: #aaa;
				border: 1px solid #333;
				color: #FFF;

				&::after {
					content: 'Training';
					text-transform: uppercase;
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					line-height: 25px;
					text-align: center;
				}
			}
		}

		&.snack {
			height: 50px;
			background-color: #f5f5f5;
			border: 1px solid #eaeaea;
			color: #333;
			text-align: center;
			cursor: pointer;
			line-height: 25px;

			&:not(.active) {
				opacity: 0;
			}
		}

		&.breakfast, &.lunch, &.dinner {
			height: 100px;
			background-color: #f5f5f5;
			border: 1px solid #eaeaea;

			@import "meal-tile";

		}
	}

	.actions {
		padding-top: 25px;
		text-align: center;

		.btn {
			i.fa-check {
				font-size: 0.7rem;
				position: absolute;
			}
		}
	}
}

.nutritional-values-wrapper, .options-wrapper {
	text-align: center;

	.form-inline {
		justify-content: center;
	}

	input.missing {
		text-align: left;
		max-width: 80%;
		margin: 0 auto;
	}
}

.meal-plan-properties {
	font-size: 1.2em;
}

.shopping-list {
	.shopping-list-amount {
		white-space: nowrap;
		padding-right: 5px;

		&:before {
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\f096";
			margin-right: 10px;
		}
	}

	.shopping-list-item-name {
		padding-left: 5px;
	}

	/*li {
		&:hover {
			background-color: #eaeaea;
		}
	}

	.row {
		width: 100%;
	}*/
}
