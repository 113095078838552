.value-display {
	display: inline-block;
	text-align: center;
	padding: 2em;
	border-bottom: 1px solid #000;

	.value {
		font-size: 2em;
		font-weight: 200;
	}
}