.bread-crumb {
	margin-bottom: 2em;
	
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin: 0 -0.5em;


		li {
			display: inline-block;
			padding: 0 0.5em;

		}
	}
}