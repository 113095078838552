.meal-tile {
	background-color: #fff;
	cursor: pointer;
	border-radius: 5px;	
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 1em;
	font-size: 0.9em;

	&:hover {
		background-color: darken(#f5f5f5, 1%);
		box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	}	

	.visible-expanded {
		display: none;
	}

	.recipe-title {
		display: block;
		width: 100%;
		height: 100%;
		text-align: center;
		line-height: 2;
		font-size: 1.2em;
		overflow: hidden;
	}

	&.expand {
		height: auto;
		z-index: 500;
		box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);

		.visible-print {
			display: inherit;
		}

		.hidden-print {
			display: none;
		}

		.visible-expanded {
			display: inherit;
		}
	}

	ul.ingredients {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			.weight {
				font-weight: bold;
				display: inline-block;
				width: 35px;
			}
		}
	}
}