.modal-meal {
	.modal-dialog {
		width: 80%;
		max-width: 1000px;
		-webkit-overflow-scrolling: touch;
		max-height: calc(100vh - 60px);
		overflow: auto;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 0.3rem;
	}

	.modal-content {
		border: none;
		border-radius: 0;
	}

	.modal-body {
		h5 {
			margin-bottom: 1em;
		}

		.description {
			white-space: pre-wrap;
		}

		.col-amount {
			width: 25%;
		}
	}

	
}