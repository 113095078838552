.navbar, .bread-crumb, .app-foot {
	display: none;
}

.container, .container-fluid {
	&.white {
		border-width: 0;
		background-color: transparent;
	}

	&.with-padding {
		margin-top: 0;
	}
}

.meal-plan-wrapper {
	width: 100%;

	.meal-plan {

		.meal-block {

			&.breakfast, &.lunch, &.dinner {
				height: 200px;
			}
		}

		.row.head {
			color: #FFF;
		}
	}
}

html {
	background: linear-gradient(to bottom, #66a3f6 0%,#3e476b 100%);
}

body {
	background-color: transparent;
}