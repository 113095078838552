@keyframes wortmarke {
    0% {
    	transform: translateX(0px);
    	opacity: 1;
    }
    30% {
    	transform: translateX(500px);
    	opacity: 0;
    }
    50% {
    	transform: translateX(-500px);
    	opacity: 0;
    }
    100% {
    	transform: translateX(0px);
    	opacity: 1;
    }
}

@keyframes rotateCircle {
    0% {
    	transform: rotate(0deg);
    }
    10% {
    	transform: rotate(0deg);
    }
    50% {
    	transform: rotate(359deg);
    }
    100% {
    	transform: rotate(360deg);
    }
}

.loading-indicator {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2000;
	background-color: rgba(250,250,250, 1);
	transition: opacity 1s;
	opacity: 0;
	pointer-events: none;

	&.is-loading {
		opacity: 1;
		pointer-events: auto;
	}

	.spinner {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.calculate-svg {
			width: 40%;


			svg {
				max-width: 100%;
        overflow: visible;

				#wortmarke {
					animation: wortmarke 5s infinite;
				}

				#loadingCircle {
					animation: rotateCircle 5s infinite;
					transform-origin: 17% 50%;
					animation-fill-mode: forwards;
				}
			}
		}

		.loading-purpose {
			font-size: 3em;
			color: $brand-primary;
			margin-top: 1em;
			text-align: center;
		}
	}
}
