.tag-element {
	display: inline-block;
	background-color: $tag-background-color;
	border: 1px solid $tag-border-color;
	border-radius: $tag-border-radius;
	padding: $spacer * .15;
	margin-right: $spacer * .25;
	margin-bottom: $spacer * .25;
	cursor: pointer;
	position: relative;

	.remove {
		display: inline-block;
		color: #999;

		&:hover {
			color: #333;
		}

		&::after {
			font-family: FontAwesome;
			content: "\f00d";
			padding-right: $spacer * .25;
			padding-left: $spacer * .25;
		}
	}

	.dropdown-menu {
		width: 300px;
	}

	&.has-state {
		padding-left: $spacer * 1.5;

		$bullet-size: $spacer * 0.8;

		&::before {
			content: "";
			width: $bullet-size;
			height: $bullet-size;
			display: block;
			position: absolute;
			background-color: #FFF;
			border-radius: $bullet-size * 0.5;
			left: $spacer * .3;
			top: $spacer * .5;
			border: 1px solid #CCC;
			box-shadow: inset 0px 0px 0px 2px #FFF;
		}

		&.active {
			&::before {
				background-color: $brand-primary;
			}
		}
	}
}