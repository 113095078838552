.main-menu {
	.menu-row {
		height: 200px;
		
		.col {
			&:nth-child(2) {
				.responsive-tile {
					transition-delay: 25ms;
				}
			}

			&:nth-child(3) {
				.responsive-tile {
					transition-delay: 50ms;
				}
			}
		}
	}	


	&:not(.initialized) {
		.responsive-tile {
			transform: translate(0, -80px);
			opacity: 0.1;
		}
	}
}

.modal-dialog {
	max-width: 50vw;
	min-width: 550px;
}

.customer-modal {
	.table-fixed{
		width: 100%;

		tbody{
			height:200px;
			overflow-y:auto;
			width: 100%;
		}
		thead,tbody,tr,td,th{
			display:block;
		}
		tbody{
			a {
				color: #555;
			}

			
			td{
				float:left;
				width: 25%;
				white-space: nowrap;
			}
		}
		thead {
			tr{
				th{
					float:left;
					width: 25%;
				}
			}
		}
	}
}