.info-popup {
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: $tag-border-color;

  .popup {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100vw;
    max-width: 300px;
    background-color: white;
    padding: 1rem;
    box-shadow: rgba(0,0,0,0.3) 0 0 5px;
    z-index: 100;
    border-radius: 1em;
    color: #555;
  }
}
