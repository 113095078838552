.app-foot {
	text-align: center;
	padding: 1em 0;
	position: fixed;
	bottom: 0;
	background-color: #FFF;
	width: 100%;
	border-top: 1px solid $brand-primary;

	img {
		max-width: 150px;
		margin-bottom: 2.5em;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;

			&:not(:last-child) {
				margin-right: 1em;
				padding-right: 1em;
				border-right: 1px solid #777;
			}

			a {
				color: #777;
			}
		}
	}
}