.auth-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	margin: 0;
	background-color: #FFF;
	background-image: url('images/bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	display: none;
	z-index: 900;
  overflow-y: auto;

	&.show {
		display: block;
	}

  &.show-registration {
    .form-register {
      display: block;
    }

    .form-login {
      display: none;
    }
  }

	.form-login, .form-register {
		margin-top: 250px;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
    margin-bottom: 50px;

		h2 {
			margin-bottom: 0.5em;
			text-align: center;
		}

		input {
			margin-bottom: 0.8em;

      &[type="date"] {
        min-height: 2.3em;
      }

		}

		.btn {
			max-width: 250px;
			margin-left: auto;
			margin-right: auto;
		}

    .with-unit {
      text-align:right;
    }

    .input-group-append {
      margin-left: -1px;

      .input-group-text {
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: .375rem .75rem;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        text-align: center;
        white-space: nowrap;
        background-color:#e9ecef;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
	}

  .form-register {
    display: none;
    margin-top: 50px;
  }
}
