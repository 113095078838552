.academy {
	.col-4.category {
		margin-bottom: 2em;
	}
}

.academy-post {
	img, video {
		max-width: 100%;
	}

	.download {
		margin-bottom: 1em;
	}
}

.academy-category {
	a.post {
		&:hover {
			text-decoration: none !important;
		}
	}
	
	.card {
		height: 100%;
		color: #333;

		&:hover {
			background-color: #efefef;
			text-decoration: none !important;
		}
	}
}