@import 'node_modules/bootstrap-tokenfield/dist/css/bootstrap-tokenfield';
@import 'node_modules/jquery-autocomplete/jquery.autocomplete';

@import 'node_modules/jquery-ui/themes/base/core';
@import 'node_modules/jquery-ui/themes/base/autocomplete';
@import 'node_modules/jquery-ui/themes/base/theme';

.bootstrap-tokenfield {
	.xdsoft_autocomplete_hint {
		background-color: transparent;
	}

	.tokenfield {
		.token {
			position: relative;
			z-index: 1000;
		}
	}
}