$tile-bg: $bright-grey;

.responsive-tile {
	display: block;
	width: 100%;
	height: 100%;
	background-color: $tile-bg;
	border: 1px solid #f0f0f0;
	color: #444;
	transition: all 250ms;
	transition-timing-function: $default-easing;
	position: relative;
	overflow: hidden;
	box-shadow: inset 0px 0px 0px -10px $brand-primary;

	a, .no-link {
		text-decoration: none;
		color: inherit;
		position: relative;
		padding: 1.5em;
		width: 100%;
		height: 100%;
		display: block;
	}

	i {
		position: absolute;
		left: 0;
		height: 100%;
		top: 0;
		bottom: 0;
		width: 50%;
		color: $brand-primary;
		font-size: 6em;
		margin-left: -0.2em;
		transition: margin-left 250ms;
		transition-timing-function: $default-easing;
	}

	&.has-icon {
		.tile-title, .tile-subtitle {
			padding-left: 20%;
		}
	}

	

	.tile-title {
		margin-bottom: 1em;
		font-size: 1.5em;
		font-weight: 200;
	}

	&.clickable {
		cursor: pointer;

		&:hover {
			background-color:  lighten($tile-bg, 2%);
			box-shadow: inset 0px -15px 0px -10px $brand-primary;

			i {
				margin-left: -0.1em;
			}
		}
	}

	&.compact {
		max-height: 130px;

		a, .no-link {
			padding: 1em;
		}

		.tile-title {
			margin-bottom: 0.8em;
		}
	}
}