.user-info {
	text-align: right;
	margin-left: 2em;

	.nav-bit {
		cursor: pointer; 

		&:hover {
			.icon i {
				transform: translateY(6px);
			}
		}

		.attributes div {
			display: block;
			color: #555;
			line-height: $navbar-inner-height;
		}

		.icon i {
			line-height: $navbar-inner-height;
			transition: transform 0.25s, opacity 0.25s;
			transition-timing-function: $default-easing;
		}
	}

		
	.dropdown {
		text-align: left;
		position: absolute;
		right: 15px;
		top: calc(100% + 0.5rem);
		width: 300px;

		.name {
			font-weight: 200;
			font-size: 1.4em;
		}

		.icon {
			font-size: 2em;
			color: darken($bright-grey, 20%);
		}

		li.link {
			&:hover {
				background-color: $bright-grey;
				cursor: pointer;
			}
		}
	}
}