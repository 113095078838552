.settings-wrapper {
	.branch-selection {
		margin-bottom: 2em;
	}
	
	.values {
		.value-display {
			width: 100%;
		}
	}

	.spectrum-color-picker-replacer {
		border-width: 0;
		border-radius: 0.25rem;
		padding: 0.5rem;
	}

	.form-group {
		.col-5 {
			text-align: right;
			
		}

		button.image-btn {
			width: 100%;
			color: transparent;
			background-repeat: no-repeat;
			background-size: 80%;
			background-position: center;
			cursor: pointer;

			&:hover {
				color: #333;
				text-shadow: 0px 0px 2px rgba(255, 255, 255, 1);
			}

			&.doc {
				line-height: 150px;
			}
		}
	}

	.preview-meal-plan-wrapper {
		padding: 0.5em;
		background-color: #FFF;
		border: 1px solid #ccc;
		margin-bottom: 2em;

		img {
			width: 100%;
		}

		.preview-meal-plan {
			font-size: 0.5em;
			padding: 1em;
			background: linear-gradient(to bottom, #66a3f6 0%,#3e476b 100%);
			height: 350px;

			.head {
				padding: 0 10px;
				
				img {
					width: 90px;
				}

				span {
					font-size: 1.3em;
					margin-top: 0.5em;
					display: inline-block;
				}
			}

			.plan {
				.days {
					margin: 2em 0;
					text-transform: uppercase;
					text-align: center;
				}

				.training, .meal {
					padding: 0 15px;

					.col {
						border-radius: 3px;
						background-color: #FFF;
						border: 1px solid #000;
						margin: 0.5em 1em;
						position: relative;
						transition: all 1s;
					}

					&.training {
						.col {
							height: 15px;

							&::after {
								content: "Training";
								text-transform: uppercase;
								position: absolute;
								left: 0;
								right: 0;
								width: 100%;
								height: 100%;
								text-align: center;
							}
						}
					}

					&.meal {
						.col {
							height: 50px;

							&::after {
								content: "1g Lebensmittel \A 1g Lebensmittel \A 1g Lebensmittel";
								white-space: pre;
								text-transform: uppercase;
								position: absolute;
								left: 0;
								right: 0;
								width: 100%;
								height: 100%;
								text-align: left;
								padding: 1em;
								font-size: 0.5em;
							}
						}
					}
				}
			}
		}	
	}
	
}