.meal-plan-navigator {
	margin-left: auto;
	margin-right: auto;
	width: 300px;
	
	button {
		width: 100%;
		background-color: transparent;
		color: #333;
		border-width: 0;
		margin-bottom: 2.5em;
		font-size: 1.5em;

		&:hover, &:focus, &:active {
			background-color: rgba(0,0,0,0.1);
			color: #333;
		}
	}

	.dropdown-menu {
		width: 100%;
		text-align: center;
	}
}