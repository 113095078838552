.food-list {
	position: relative;

	.food-list-input {
		display: flex;
		flex-flow: row wrap;
		padding-bottom: $spacer * .25;

		input {
			border-width: 0;
			flex-grow: 1;
			margin-bottom: $spacer * .25;
			padding: $spacer * .15;
		}
		
	}

	.dropdown-menu {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		height: 200px;
		overflow-y: auto;
		font-size: 0.8em;

		.list-group-item.food {
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			padding-left: 2.5em;
		}

		li {
			cursor: pointer;

			&:hover {
				background-color: $hover-background;
			}
		}
	}
	
}