.navbar {
	background-color: #FFF;
	box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);
	z-index: 1000;

	.navbar-brand {
		position: relative;

		img {
			max-height: $navbar-inner-height;
		}

		&::after {
			content: "Zum Hauptmenü";
			display: inline-block;
			position: absolute;
			line-height: $navbar-inner-height;
			left: 120%;
			top: .25rem;
			color: #aaa;
			font-size: 0.9em;
			transition: transform 0.25s, opacity 0.25s;
			transition-timing-function: $default-easing;
			transform: translate(30px, 0);
			opacity: 0;
		}

		&:hover {
			&::after {
				transform: translate(0, 0);
				opacity: 1;
			}
		}
	}

	li.nav-item {
		& > span {
			line-height: $navbar-inner-height;
			color: #555;
			cursor: pointer;
		}
		
	}
}

.container {
	position: relative;
}