.anamnesis-form {
	.form-check-inline {
		.form-check-label {
			i {
				overflow: hidden;
			}
		}

	}

  fieldset {
    transition: transform 1s;
  }

	img.body-type {
		height: 15em;
    display: block;
    margin-top: 1em;
    cursor: pointer;
	}

  .activity-row {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      margin-bottom: 2em;
    }
  }

	.activity {
		img {
			max-height: 15px;
		}
	}

	.ember-power-select-trigger {
		height: 38px;
		line-height: 2.25;
	}

	.food-list {
		.ember-power-select-trigger {
			height: auto;
		}
		.ember-basic-dropdown-trigger--above .ember-power-select-trigger-multiple-input,
		.ember-basic-dropdown-trigger--below .ember-power-select-trigger-multiple-input {
			clear: left;
			margin-left: 2px;
		}

		.ember-power-select-multiple-option {
			line-height: 1.5;

/* 			set additional margins between selected elements
			margin-right: 0.25rem;

			&:last-of-type {
				margin-right: 0;
			}
*/
		}
	}

  .time-toggle {
    .btn {
      flex-grow: 1;
    }
  }
}

.pika-single {
	display: none;
}
