/*
 * Bootstrap
 */

$brand-primary: rgba(192, 211, 0, 1);

$theme-colors: (
  primary: $brand-primary,
  secondary: #868e96,
  success: #28a745,
  info: #17a2b8,
  warning: #ffc107,
  danger: #dc3545,
  light: #f8f9fa,
  dark: #343a40
);

$body-bg: #f7f7f7;

@import "ember-cli-bootstrap-4/bootstrap";

$link-color: darken($brand-primary, 5%);


/*
 * Addons
 */

@import 'bootstrap-tokenfield';

/*
 * Custom vars
 */

$navbar-inner-height: 60px;
$bright-grey: #fcfcfc;
$default-easing: cubic-bezier(0.64, 0.57, 0.67, 1.53);
$hover-background: rgba(100,100,100,0.02);

$tag-background-color: #e4e4e4;
$tag-border-color: gray;
$tag-border-radius: 4px;

/*
 * Custom directives
 */
body, html {
	min-height: 100%;
}

body {
	padding-bottom: 6em;

  &.compact-mode {
    .hide-compact {
      display: none;
    }

    .loading-indicator .spinner .loading-purpose {
      color: #F5B324;
    }

    #tidio-chat {
      display: none;
    }
  }

  &:not(.compact-mode) {
    .compact-only {
      display: none;
    }
  }
}



h2 {
	text-transform: uppercase;
}

.btn {
	text-transform: uppercase;
	cursor: pointer;
}


.navbar-nav {
	flex-direction: row;
}

.container, .container-fluid {
	&.with-padding {
		margin-top: $navbar-inner-height * 2;
	}

	&.white {
		background-color: $bright-grey;
		border-top: 1px solid #f0f0f0;
		border-bottom: 1px solid #f0f0f0;
	}
}

.content {
	padding: 2em 0;
}

.modal {
	pointer-events: none;

	.modal-dialog {
		pointer-events: auto;
	}

	.close {
		cursor: pointer;
	}
}

.modal-backdrop {
	cursor: pointer;
}


a.nav-link {
	cursor: pointer;
}

@import 'main-menu';
@import 'responsive-tile';
@import 'bread-crumb';
@import 'anamnesis-form';
@import 'app-foot';
@import 'meal-plan';
@import 'value-display';
@import 'settings';
@import 'customer';
@import 'auth-overlay';
@import 'main-nav';
@import 'user-info';
@import 'lazy-var';
@import 'modal-meal';
@import 'loading-indicator';
@import 'meal-plan-navigator';
@import 'food-list';
@import 'tag-element';
@import 'academy';
@import 'info-popup';

@media screen {
	.visible-print {
		display: none;
	}
}

@media print {
	@import 'print';

	.visible-print {
		display: inherit;
	}
}
@import "ember-power-select";

.ember-power-select-multiple-option {
	line-height: 1.9;
}

.app-content {
  &.compact-mode {
    .modal-dialog {
      min-width: 350px !important;
    }

    .meal-plan-wrapper {
      .row-wrapper {
        @media (max-width: 800px) {
          & > .row {
            min-width: calc(100vw * 7);

            & > .col {
              flex-basis: 14%;
              flex-grow: 0;
              flex-shrink: 0;
            }
          }
        }

      }
    }

    .auth-overlay {
      background-image: url('images/bg_compact.jpg');
    }

    .bread-crumb {
      display: none;
    }

    .navbar {
      display: none;
    }

    .container, .container-fluid {
      &.with-padding {
        margin-top: $navbar-inner-height * 0.5;
      }
    }

    .anamnesis-form {
      .anamnesis-slide {
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(75vh - 50px);

        fieldset {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          padding-bottom: 1em;
        }
      }
      .compact-controls {
        margin-top: 1em;
      }
    }
  }

  &:not(.compact-mode) {
    .btn-register {
      display: none;
    }

    .compact-controls {
      display: none;
    }
  }
}

#ember-basic-dropdown-wormhole {
  .ember-basic-dropdown-content {
    z-index: 10000;
  }
}

